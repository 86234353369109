
@tailwind base;
@tailwind components;
@tailwind utilities;

*::-webkit-scrollbar {
    width: 9px;
    height: 5px;
  }
  *::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius : 25px;
  }
  *::-webkit-scrollbar-thumb {
    background-color: #dbdbdb;
    border-radius : 25px;
  }
  
  .css-q42q6u-MuiPaper-root-MuiDrawer-paper{
    overflow-y: hidden !important;
  }

  .gridItem{
    &:hover{
        cursor: pointer;
        transform: scale(0.95);

    } &:active{
        transform: scale(0.9);
        // border: 1px solid !important;
    }
  
}

.hoverLink {
  &:hover{
    cursor: pointer;
    transform: scale(0.95);
    text-decoration: underline;

} &:active{
    transform: scale(0.9);
    // border: 1px solid !important;
}
}

body{
  font-family: outfitRegular, 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}