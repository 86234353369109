.css-1ahqq1t-MuiTableCell-root:first-of-type{
    box-shadow: none !important;
}

.css-1ahqq1t-MuiTableCell-root:last-of-type{
    box-shadow: none !important;
}

.MuiDataGrid-viewport,
.MuiDataGrid-row,
.MuiDataGrid-renderingZone {
  max-height: fit-content !important;
}
.MuiDataGrid-cell:not(.font-tabular-nums),
.MuiDataGrid-colCellTitle {
  max-height: fit-content !important;
  overflow: auto;
  max-height: inherit;
  white-space: initial !important;
  line-height: 16px !important;
  display: flex !important;
  align-items: center;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.MuiDataGrid-cell > div {
  max-height: inherit;
  width: 100%;
  white-space: initial;
  line-height: 16px;
}

html, body {
  font-family: outfitLight, outfitRegular, outfitMedium;
}

div {
  font-family: outfitRegular;
}

p {
  font-family: outfitRegular;
}

h1, h2, h3, h4, h5, h6 {
  font-family: outfitSemiBold;
}

button {
  font-family: outfitSemiBold;
}

.clickable {
  cursor: pointer;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}

.clickable:hover {
  -webkit-transform: scale(.95);
  transform: scale(.95);
}

.clickableLink {
  font-weight: normal;
  text-decoration: none;
  cursor: pointer;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}

.clickableLink:hover {
  font-weight: bold;
  text-decoration: none;
  -webkit-transform: scale(.97);
  transform: scale(.97);
}
