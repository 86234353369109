.pageLinksOuterContainer {
  width : 100%;
  display : flex;
  flex-direction : row;
  /* background-color: red; */
}

.pageSocialOuterContainer {
  width : 100%;
  display : flex;
  flex-direction : row;
  /* background-color: pink; */
}

.pageLinksInnerContainer {
  width : fit-content;
  display: flex;
  flex-direction : column;
  align-items : flex-start;
}

.pageLinksItemsContainer {
  display : flex;
  gap : 2.5px;
  flex-wrap: wrap;
}

.socialLinksItemsContainer {
  display : flex;
  gap : 7.5px;
  flex-wrap: wrap;
}

.footerSectionHeader {
  font-size : 110%;
  color : white;
  font-family : 'outfitSemiBold';
  line-height : 1.2;
}

.copyrightText {
  font-size : 100%;
  font-family : 'outfitLight';
  font-weight : light;
  color : white;
  font-size : 90%;
  width : 100%;
}

/**** MEDIA moduel ***/

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 600px) {

  .pageLinksOuterContainer {
    justify-content : flex-start;
  }

  .pageSocialOuterContainer{
    justify-content : flex-start;
  }
  
  .pageLinksItemsContainer {
    flex-direction : row;
    gap: 15px;
    flex-wrap: wrap;
  }
  
  .socialLinksItemsContainer {
    flex-direction : row;
    gap: 15px;
    flex-wrap: wrap;
  }

  .footerSectionHeader {
    margin-top : 10px
  }

  .pageLinksInnerContainer {
    gap : 2.5px;
  }

  .copyrightText {
    padding-bottom : 15px;
    padding-top : 15px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 600px) and (max-width: 900px) {

  .pageLinksOuterContainer {
    justify-content : flex-start;
  }

  .pageSocialOuterContainer{
    justify-content : flex-start;
  }

  .pageLinksItemsContainer {
    flex-direction : row;
    gap: 15px;
    flex-wrap: wrap;
  }
  
  .socialLinksItemsContainer {
    flex-direction : row;
    gap: 15px;
    flex-wrap: wrap;
  }

  .footerSectionHeader {
    margin-top : 10px
  }

  .pageLinksInnerContainer {
    gap : 2.5px;
  }

  .copyrightText {
    padding-bottom : 15px;
    padding-top : 15px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 900px) and (max-width: 1200px) {

  .pageLinksOuterContainer {
    justify-content : center;
  }

  .pageSocialOuterContainer{
    justify-content : center;
  }

  .pageLinksItemsContainer {
    flex-direction : column;
  }
  
  .socialLinksItemsContainer {
    flex-direction : column;
  }

  .footerSectionHeader {
    margin-top : 50px
  }

  .pageLinksInnerContainer {
    gap : 5px;
  }

  .copyrightText {
    padding-bottom : 15px;
    padding-top : 5px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 1200px) and (max-width: 1800px) {

  .pageLinksOuterContainer {
    justify-content : center;
  }

  .pageSocialOuterContainer{
    justify-content : center;
  }

  .pageLinksItemsContainer {
    flex-direction : column;
  }
  
  .socialLinksItemsContainer {
    flex-direction : column;
  }

  .footerSectionHeader {
    margin-top : 50px
  }

  .pageLinksInnerContainer {
    gap : 5px;
  }

  .copyrightText {
    padding-bottom : 15px;
    padding-top : 5px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1800px) {

  .pageLinksOuterContainer {
    justify-content : center;
  }

  .pageSocialOuterContainer{
    justify-content : center;
  }

  .pageLinksItemsContainer {
    flex-direction : column;
  }
  
  .socialLinksItemsContainer {
    flex-direction : column;
  }
  
  .footerSectionHeader {
    margin-top : 50px
  }

  .pageLinksInnerContainer {
    gap : 5px;
  }

  .copyrightText {
    padding-bottom : 15px;
    padding-top : 5px;
  }

}
